import {
  Component,
  EventEmitter,
  Inject,
  LOCALE_ID,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Functions } from '@angular/fire/functions';
import { Firestore } from '@angular/fire/firestore';

import {
  ButtonModule,
  CardComponent,
  IconModule,
  ListModule,
  ModalComponent,
  RadioComponent,
  RadioGroupComponent,
  TestimonialComponent,
  TextAreaComponent,
  TextFieldComponent,
  ToastService,
  UIState,
} from '@yoimo/joymo-ui';
import {
  cancelSubscription,
  getUserSubscription$,
  pauseSubscription,
  PlanWithDocId,
  SubscriptionWithDocId,
} from '@yoimo/client-sdk/subscriptions';
import {
  catchError,
  filter,
  first,
  map,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { PauseBehaviour, ViewerRetentionSettings } from '@yoimo/interfaces';
import {
  canPauseSubscription,
  getSubscriptionPauseBehaviour,
} from '@yoimo/client-sdk/business-logic';
import { getChannelPublicSettings } from '@yoimo/client-sdk/channels';

import { getDateString } from 'shared-lib/core';
import { PlatformService, ScopeService } from '@core/services';
import { AsFormControlPipe } from '@core/pipes';

type CancellationStep =
  | 'CANCEL_SUBSCRIPTION'
  | 'CONFIRM_CANCEL_SUBSCRIPTION'
  | 'PAUSE_SUBSCRIPTION'
  | 'RETENTION';

type CancellationStepConfig = {
  nextStep?: { label: string; step: CancellationStep };
  submitLabel: string;
};

const actionLabels = {
  cancelInstead: $localize`:@@cancelSubscriptionButtonLabelCancelInstead:Cancel instead`,
  completeCancellation: $localize`:@@cancelSubscriptionButtonLabelCompleteCancellation:Complete cancellation`,
  confirmPlanChange: $localize`:@@cancelSubscriptionButtonLabelConfirmPlanChange:Confirm plan change`,
  continueWithCancellation: $localize`:@@cancelSubscriptionButtonLabelContinueToCancellation:Continue with cancellation`,
  keepSubscription: $localize`:@@cancelSubscriptionButtonLabelKeepSubscription:Keep subscription`,
  pauseSubscription: $localize`:@@cancelSubscriptionButtonLabelPauseSubscription:Pause my subscription`,
  showPauseOptions: $localize`:@@cancelSubscriptionButtonLabelShowPauseOptions:Show me pause options`,
} as const;

/** Handles the process to pause/cancel a subscription */
@Component({
  selector: 'joymo-cancellations',
  standalone: true,
  imports: [
    CommonModule,
    ModalComponent,
    ButtonModule,
    RadioGroupComponent,
    RadioComponent,
    TextFieldComponent,
    FormsModule,
    ReactiveFormsModule,
    IconModule,
    TextAreaComponent,
    CardComponent,
    ListModule,
    AsFormControlPipe,
    TestimonialComponent,
  ],
  templateUrl: './cancellations.component.html',
  styleUrls: ['./cancellations.component.scss'],
})
export class CancellationsComponent implements OnDestroy {
  @ViewChild(ModalComponent) modal?: ModalComponent;
  @Output() onSubscriptionStateChanged = new EventEmitter<void>();

  readonly stepsConfig: Record<CancellationStep, CancellationStepConfig> = {
    RETENTION: {
      submitLabel: actionLabels.keepSubscription,
      nextStep: {
        step: 'CANCEL_SUBSCRIPTION',
        label: actionLabels.continueWithCancellation,
      },
    },
    PAUSE_SUBSCRIPTION: {
      submitLabel: actionLabels.pauseSubscription,
      nextStep: {
        step: 'CANCEL_SUBSCRIPTION',
        label: actionLabels.continueWithCancellation,
      },
    },
    CANCEL_SUBSCRIPTION: {
      submitLabel: actionLabels.keepSubscription,
      nextStep: {
        step: 'CONFIRM_CANCEL_SUBSCRIPTION',
        label: actionLabels.continueWithCancellation,
      },
    },
    CONFIRM_CANCEL_SUBSCRIPTION: {
      submitLabel: actionLabels.completeCancellation,
    },
  };

  /** Title is unknown until the subscription is provided */
  readonly title = $localize`:@@cancelSubscriptionTitle:Cancel subscription`;
  readonly cancelReasons: string[] = [
    $localize`:@@cancelSubscriptionReasonNoLongerNeeded:I no longer need it`,
    $localize`:@@cancelSubscriptionReasonTooExpensive:It is too expensive`,
    $localize`:@@cancelSubscriptionReasonFoundAlternative:I found an alternative`,
    $localize`:@@cancelSubscriptionReasonLowQuality:Quality was less than expected`,
  ];
  pausePros: string[] = [
    $localize`:@@pauseSubscriptionPriceRemainsFixedInfo:Subscription price remain fixed. Any price change during the pause period won’t affect you.`,
  ];
  state: UIState = 'idle';
  step: CancellationStep = 'CANCEL_SUBSCRIPTION';

  readonly channel$ = this.scopeService.listenToChannel();
  readonly subscription$ = new ReplaySubject<SubscriptionWithDocId>(1);
  readonly retention$ = this.getRetention$();

  readonly feedbackForm = new FormGroup({
    reason: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    customReason: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    improvement: new FormControl<string>('', { nonNullable: true }),
  });
  readonly customReasonLabel = $localize`:@@cancelSubscriptionCustomReasonLabel:Please indicate the reason`;
  readonly customReasonOption = $localize`:@@cancelSubscriptionReasonOther:Other`;

  protected pauseBehaviour?: PauseBehaviour;
  protected selectedPausePeriod?: number | Date;

  private readonly destroy$ = new Subject<void>();

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private ff: Functions,
    private fs: Firestore,
    private platformService: PlatformService,
    private scopeService: ScopeService,
    private toastService: ToastService
  ) {
    if (this.platformService.isServer()) {
      return;
    }
    this.feedbackForm.controls['reason'].valueChanges
      .pipe(takeUntil(this.destroy$), filter(Boolean))
      .subscribe(() => (this.state = 'idle'));
  }

  open(subscription: SubscriptionWithDocId, plan: PlanWithDocId): void {
    this.subscription$.next(subscription);

    this.pauseBehaviour = this.getPauseConfig(subscription, plan);

    if (this.pauseBehaviour) {
      this.pausePros = this.pauseBehaviour.texts.pros;
      this.selectedPausePeriod = this.pauseBehaviour.periods[0].period;
    }

    this.setInitialStep(!!this.pauseBehaviour);
    this.modal?.open();
  }

  submit(
    step: CancellationStep,
    channelId: string,
    subscriptionId: string
  ): void {
    if (step === 'RETENTION' || step === 'CANCEL_SUBSCRIPTION') {
      return this.modal?.close();
    }
    if (step === 'PAUSE_SUBSCRIPTION') {
      return this.pauseSubscription(channelId, subscriptionId);
    }
    if (step === 'CONFIRM_CANCEL_SUBSCRIPTION') {
      return this.cancelSubscription(channelId, subscriptionId);
    }
    throw new Error('UnhandledCancellationStep::' + step);
  }

  goToStep(step: CancellationStep): void {
    if (step === 'CONFIRM_CANCEL_SUBSCRIPTION') {
      if (!this.isFormValid()) return;
    }

    this.step = step;
  }

  cancelSubscription(channelId: string, subscriptionId: string): void {
    if (!this.isFormValid()) return;

    this.state = 'loading';

    const { reason, customReason, improvement } = this.feedbackForm.value;

    cancelSubscription(
      this.ff,
      channelId,
      subscriptionId,
      [customReason || reason, improvement].join(':')
    )
      .pipe(
        takeUntil(this.destroy$),
        catchError((err) => of({ success: false, errorReason: err })),
        take(1)
      )
      .subscribe((res) => {
        this.state = 'idle';
        this.modal?.close();

        if (!res.success) {
          this.toastService.open(
            $localize`:@@subscriptionCancelFailedToastMessage:Cancellation failed`,
            { type: 'error' }
          );
          throw new Error(res.errorReason);
        }

        this.toastService.open(
          $localize`:@@subscriptionCancelSuccessToastMessage:Subscription has been cancelled`
        );
        this.onSubscriptionStateChanged.emit();
      });
  }

  pauseSubscription(channelId: string, subscriptionId: string): void {
    if (this.selectedPausePeriod === undefined) {
      throw new Error('PausePeriodMustBeDefined');
    }

    this.state = 'loading';

    pauseSubscription(
      this.ff,
      channelId,
      subscriptionId,
      this.selectedPausePeriod
    )
      .pipe(
        catchError((e) => of({ success: false, errorReason: e })),
        switchMap((res) => {
          if (!res.success) {
            this.modal?.close();
            this.toastService.open(
              $localize`:@@pauseSubscriptionFailedMessage:Pause subscription failed`,
              { type: 'error' }
            );
            this.state = 'error';
            throw new Error(res.errorReason);
          }

          return getUserSubscription$(this.fs, channelId, subscriptionId);
        }),
        first((sub) => sub?.status === 'PAUSED')
      )
      .subscribe(() => {
        this.state = 'idle';
        this.onSubscriptionStateChanged.emit();
        this.modal?.close();
        this.toastService.open(
          $localize`:@@pauseSubscriptionSuccessMessage:Subscription has been paused`
        );
      });
  }

  getPauseUntilDate(period: number | Date): string {
    if (period instanceof Date) {
      return getDateString(period, this.locale);
    }
    const date = new Date();
    date.setDate(date.getDate() + period);
    return getDateString(date, this.locale);
  }

  isFormValid(): boolean {
    const { reason, customReason } = this.feedbackForm.controls;

    this.feedbackForm.markAllAsTouched();

    if (
      !reason.value ||
      (reason.value === this.customReasonOption && !customReason.value)
    ) {
      this.state = 'error';
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getPauseConfig(
    sub: SubscriptionWithDocId,
    plan: PlanWithDocId
  ): PauseBehaviour | undefined {
    const pauseBehaviour = canPauseSubscription(sub, plan)
      ? getSubscriptionPauseBehaviour(sub, plan)
      : undefined;

    if (!pauseBehaviour) {
      return undefined;
    }

    pauseBehaviour.periods = pauseBehaviour.periods.filter(
      ({ period }) => !(period instanceof Date) || period.getTime() > Date.now()
    );
    return pauseBehaviour;
  }

  private getRetention$(): Observable<ViewerRetentionSettings | undefined> {
    return this.channel$.pipe(
      switchMap((c) => {
        this.state = 'loading';
        return getChannelPublicSettings(this.fs, c.docId);
      }),
      map(({ viewerRetentionSettings }) => {
        this.state = 'idle';
        return viewerRetentionSettings;
      })
    );
  }

  private setInitialStep(canBePaused: boolean): void {
    if (canBePaused) {
      this.stepsConfig.RETENTION.nextStep!.step = 'PAUSE_SUBSCRIPTION';
      this.goToStep('RETENTION');
      return;
    }

    this.goToStep('CANCEL_SUBSCRIPTION');
  }
}

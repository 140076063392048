<yo-modal *ngIf="channel$ | async as channel" [title]="title">
  <ng-container yo-modal-content *ngIf="subscription$ | async as subscription">
    <ng-container [ngSwitch]="step">
      <!-- Steps' templates -->
      <ng-container *ngSwitchCase="'RETENTION'">
        <ng-container *ngTemplateOutlet="retentionTmpl"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'PAUSE_SUBSCRIPTION'">
        <ng-container *ngTemplateOutlet="suggestPauseTmpl"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'CANCEL_SUBSCRIPTION'">
        <ng-container
          *ngTemplateOutlet="
            cancelTmpl;
            context: {
              channelId: channel.docId,
              subscriptionId: subscription.docId
            }
          "
        ></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'CONFIRM_CANCEL_SUBSCRIPTION'">
        <ng-container *ngTemplateOutlet="confirmCancelTmpl"></ng-container>
      </ng-container>
    </ng-container>

    <footer
      class="border-t border-default"
      [ngClass]="{ 'm-t-40': step !== 'PAUSE_SUBSCRIPTION' }"
    >
      <div
        class="flex flex-column md:flex-row flex-justify-between flex-align-center m-t-24 gap-40"
      >
        <yo-button
          role="primary"
          [fullWidth]="true"
          [loading]="state === 'loading'"
          (click)="submit(step, channel.docId, subscription.docId)"
        >
          {{ stepsConfig[step].submitLabel }}
        </yo-button>
        <yo-button
          *ngIf="stepsConfig[step].nextStep"
          role="link"
          iconPosition="RIGHT"
          class="flex gap-4 flex-align-center clickable"
          [fullWidth]="true"
          [disabled]="state === 'loading'"
          (click)="goToStep(stepsConfig[step].nextStep!.step)"
        >
          <yo-icon icon="arrow_right"></yo-icon>
          <span class="text-semibold">
            {{ stepsConfig[step].nextStep!.label }}
          </span>
        </yo-button>
      </div>
    </footer>
  </ng-container>
</yo-modal>

<ng-template #suggestPauseTmpl>
  <section *ngIf="pauseBehaviour" [scrollTop]="0">
    <h2 class="text-lg text-semibold md:p-t-24">
      {{ pauseBehaviour.texts.header }}
    </h2>
    <yo-list class="m-t-16 md:m-t-24 gap-12 text-md-spacious">
      <yo-list-item *ngFor="let textLine of pausePros">
        <yo-icon icon="check_solid" yo-list-item-icon></yo-icon>
        <p>{{ textLine }}</p>
      </yo-list-item>
    </yo-list>
  </section>

  <section [scrollTop]="0">
    <h2
      class="text-lg text-semibold m-t-40 md:m-t-48"
      i18n="@@pauseSubscriptionHeader"
    >
      Please select the pausing period
    </h2>
    <ul
      class="flex flex-wrap gap-8 md:gap-12 m-t-16 md:m-t-24 m-b-40 md:m-b-48"
    >
      <li
        *ngFor="let period of pauseBehaviour?.periods"
        class="clickable"
        (click)="selectedPausePeriod = period.period"
      >
        <ng-container
          *ngTemplateOutlet="
            pausePeriodCard;
            context: {
              period: period,
              isSelected: selectedPausePeriod === period.period
            }
          "
        ></ng-container>
      </li>
    </ul>
  </section>

  <img
    *ngIf="pauseBehaviour"
    [src]="pauseBehaviour.texts.promoBlock?.imageUrl"
    class="block w-100 m-x-auto"
  />
</ng-template>

<ng-template #pausePeriodCard let-period="period" let-isSelected="isSelected">
  <yo-card
    [isActive]="isSelected"
    class="h-100 flex flex-column gap-24 flex-justify-between text-md"
  >
    <header class="flex flex-justify-between flex-align-start">
      <h2>
        <strong>{{ period.label }}</strong>
      </h2>
      <yo-icon *ngIf="isSelected" icon="check_solid" size="lg"></yo-icon>
    </header>
    <div>
      <p i18n="@@pauseSubscriptionPauseUntil">Pause until</p>
      <p>{{ getPauseUntilDate(period.period) }}</p>
    </div>
  </yo-card>
</ng-template>

<ng-template
  #cancelTmpl
  let-channelId="channelId"
  let-subscriptionId="subscriptionId"
>
  <section [scrollTop]="0">
    <h2 class="p-b-16 md:p-y-24 text-lg text-semibold" i18n="@@whyUnsubscribe">
      Why have you decided to unsubscribe?
    </h2>
    <form
      class="flex flex-column"
      [formGroup]="feedbackForm"
      (ngSubmit)="cancelSubscription(channelId, subscriptionId)"
    >
      <yo-radio-group>
        <yo-radio
          *ngFor="let reason of cancelReasons"
          [control]="feedbackForm.controls['reason'] | asFormControl"
          [value]="reason"
          name="reason"
        ></yo-radio>
        <yo-radio
          [control]="feedbackForm.controls['reason'] | asFormControl"
          [value]="customReasonOption"
          name="reason"
        >
        </yo-radio>
      </yo-radio-group>
      <yo-text-field
        *ngIf="feedbackForm.value.reason === customReasonOption"
        class="m-t-20"
        [control]="feedbackForm.controls['customReason'] | asFormControl"
        [label]="customReasonLabel"
      >
      </yo-text-field>
      <p
        *ngIf="state === 'error'"
        class="text-danger m-t-24"
        i18n="@@selectAReasonForUnsubscribe"
      >
        Please select a reason
      </p>
      <section class="m-t-40 md:m-t-48">
        <h2
          class="text-lg text-semibold p-b-8 md:p-b-16"
          i18n="@@cancellationFeedback"
        >
          What do you want to see more of on the platform?
        </h2>
        <yo-text-area
          name="improvement"
          [control]="feedbackForm.controls['improvement'] | asFormControl"
          [rows]="3"
        >
        </yo-text-area>
      </section>
    </form>
  </section>
</ng-template>

<ng-template #confirmCancelTmpl>
  <section class="flex flex-column gap-8" [scrollTop]="0">
    <h2 class="text-lg text-semibold m-b-8" i18n="@@confirmCancellationHeading">
      Please confirm the cancellation
    </h2>
    <p class="text-md text-md-spacious" i18n="@@confirmCancellationInfo">
      The subscription will be cancelled at the end of the billing period. You
      have access to the platform before that date.
    </p>
    <p
      class="text-md text-md-spacious"
      i18n="@@confirmCancellationTrialPeriodInfo"
    >
      If you are on the trial period, cancelling the subscription takes effect
      immediately.
    </p>
  </section>
</ng-template>

<ng-template #retentionTmpl>
  <section *ngIf="retention$ | async as retention" [scrollTop]="0">
    <h2 class="text-lg text-semibold md:p-t-24">{{ retention.title }}</h2>
    <yo-testimonial
      class="block p-t-24"
      *ngFor="let testimony of retention.testimonials"
      [imageUrl]="testimony.imageUrl"
      [name]="testimony.name"
      [quote]="testimony.quote"
      [role]="testimony.role"
    >
    </yo-testimonial>
  </section>
</ng-template>
